export var HealthIcon = {
    Bacteria: "BACTERIA",
    Barge: "BARGE",
    Beach: "BEACH",
    Bluearrow: "BLUEARROW",
    Building: "BUILDING",
    Crab: "CRAB",
    Culvert: "CULVERT",
    Eco: "ECO",
    Energystation: "ENERGYSTATION",
    Fertilizer: "FERTILIZER",
    Fish: "FISH",
    Fishalt: "FISHALT",
    Fisherman: "FISHERMAN",
    Fishing: "FISHING",
    Flats: "FLATS",
    Flock: "FLOCK",
    Freshwater: "FRESHWATER",
    Grass: "GRASS",
    Habitatalt: "HABITATALT",
    Hands: "HANDS",
    Heron: "HERON",
    Hotel: "HOTEL",
    Hurricane: "HURRICANE",
    Kayaking: "KAYAKING",
    Mangrove: "MANGROVE",
    Mixing: "MIXING",
    Money: "MONEY",
    Nest: "NEST",
    Nutrients: "NUTRIENTS",
    Oyster: "OYSTER",
    Oysterfarm: "OYSTERFARM",
    People: "PEOPLE",
    Plastic: "PLASTIC",
    Redfish: "REDFISH",
    Resilience: "RESILIENCE",
    Restoration: "RESTORATION",
    Rivers: "RIVERS",
    Seagrass: "SEAGRASS",
    Seagrassalt: "SEAGRASSALT",
    Shell: "SHELL",
    Shrimp: "SHRIMP",
    Spartina: "SPARTINA",
    Spoonbill: "SPOONBILL",
    Sunset: "SUNSET",
    Tern: "TERN",
    Waterdrop: "WATERDROP",
    Wave: "WAVE",
    Wetland: "WETLAND",
    Wildlifealt: "WILDLIFEALT"
};
export var HealthType = {
    Community: "COMMUNITY",
    Habitat: "HABITAT",
    Water: "WATER",
    Wildlife: "WILDLIFE"
};
export var ImagePosition = {
    /** Image is on the left side of the text */ Left: "LEFT",
    /** Image is on the right side of the text */ Right: "RIGHT"
};
export var Locale = {
    De: "de",
    En: "en"
};
export var SliderThemeColors = {
    Lightblue: "LIGHTBLUE",
    Lighterblue: "LIGHTERBLUE",
    White: "WHITE"
};
export var ThemeColors = {
    Lightblue: "LIGHTBLUE",
    White: "WHITE"
};
export var ContentHubQuery = "ContentHub:dc2ff25fb78058045650a591d515020902a1d79052d137b2cc4e54c50b2ac16a";
export var GlobalQuery = "Global:25988319364329be24d6e43c2127777ca91f59d509392f4abc88fcb956abd2a8";
export var PreviewPageQuery = "PreviewPage:635223bd23885f4b8fb5b1cfdcf515870153b426873c2bd55216a67bb520c1b4";
