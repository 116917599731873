import { GlobalQuery } from '@custom/schema';
import { graphql, useStaticQuery } from 'gatsby';

export function useSettings() {
  return useStaticQuery<GlobalQuery>(graphql`
    query GlobalQuery {
      ...Settings
    }
  `);
}
