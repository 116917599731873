import { SilverbackPageContext } from '@amazeelabs/gatsby-source-silverback';
import { GlobalQuery, preloadOperation } from '@custom/schema';
import { IntlProvider } from '@custom/ui/intl';
import { Frame } from '@custom/ui/routes/Frame';
import { WrapPageElementNodeArgs } from 'gatsby';
import React, { PropsWithChildren } from 'react';

import { useSettings } from '../utils/settings';

export default function Layout({
  children,
  pageContext: { locale },
}: PropsWithChildren<
  WrapPageElementNodeArgs<any, SilverbackPageContext>['props']
>) {
  preloadOperation(GlobalQuery, {}, useSettings());
  return (
    <IntlProvider locale={locale || 'en'}>
      <Frame>{children}</Frame>
    </IntlProvider>
  );
}
