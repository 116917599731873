import useSwr, { unstable_serialize } from "swr";
export { SWRConfig } from "swr";
export * from "./generated/index.js";
export * from "@amazeelabs/scalars";
var preloaded = {};
export function preloadOperation(operation, variables, result) {
    preloaded[unstable_serialize([
        operation,
        variables
    ])] = result;
}
export function useOperation(endpoint, operation, variables) {
    var _result_data;
    var preload = preloaded[unstable_serialize([
        operation,
        variables
    ])];
    var result = useSwr([
        operation,
        variables
    ], variables && !preload ? function() {
        var url = new URL(endpoint, window.location.origin);
        url.searchParams.set("queryId", operation);
        url.searchParams.set("variables", JSON.stringify(variables));
        return fetch(url, {
            credentials: "include",
            headers: {
                "SLB-Forwarded-Proto": window.location.protocol.slice(0, -1),
                "SLB-Forwarded-Host": window.location.hostname,
                "SLB-Forwarded-Port": window.location.port
            }
        }).then(function(r) {
            return r.json();
        });
    } : null, {
        suspense: false
    });
    return ((_result_data = result.data) === null || _result_data === void 0 ? void 0 : _result_data.data) || preload;
}
